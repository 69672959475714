import BaseModel from "@/models/BaseModel";

export default class ContactProfile extends BaseModel {
  static entity = "contact_profile";
  static resourceUrl = "/annuaire/contact_profile/";

  static fields() {
    return {
      id: this.uid(),
      user: this.attr(),
      first_name: this.attr(),
      last_name: this.attr(),
      email: this.attr(),
      phone_number: this.attr(),
      adresse: this.attr(),
      poste: this.attr(),
      fonction: this.attr(),
      liste_diffusion: this.attr(),
      is_public: this.attr(),
      rgpd_consent: this.attr(),
      ebes: this.attr(),
      cles: this.attr(),
    };
  }
}
