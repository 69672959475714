import BaseModel from "@/models/BaseModel";

export default class DSNImportSummary extends BaseModel {
  static entity = "dsn_import_summary";
  static resourceUrl = "/financement/dsn_import_summary/";

  static fields() {
    return {
      dsn_import: this.attr(),
      etp_contractuels_ppde: this.attr(),
      etp_contractuels_nppde: this.attr(),
      etp_travailles_ppde: this.attr(),
      etp_travailles_nppde: this.attr(),
      taux_absenteisme_ppde: this.attr(),
      taux_absenteisme_nppde: this.attr(),
      etp_total_nppde_pris_en_charge: this.attr(),
      etp_prime_conges_payes_ppde: this.attr(),
      etp_prime_conges_payes_nppde: this.attr(),
      regularisation_etp_ppde: this.attr(),
      regularisation_etp_nppde: this.attr(),
      regularisation_montant_ppde: this.attr(),
      regularisation_montant_nppde: this.attr(),
      prevision_etp_travailles_ppde: this.attr(),
      prevision_etp_travailles_nppde: this.attr(),
      prevision_etp_travailles_nppde_pris_en_charge: this.attr(),
      smic: this.attr(),
    };
  }
}
