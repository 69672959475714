import SimpleContainer from "@/views/SimpleContainer.vue";
import ListViewContainerVue from "@/views/ListViewContainer.vue";

const ManageEbe = () => import("@/views/ebe/ManageEbe.vue");

const VolunteersManagement = () => import("@/views/volunteersManagement/VolunteersManagement.vue");
const VolunteerManagement = () => import("@/views/volunteersManagement/VolunteerManagement.vue");
const EbeDeclarationMensuelleManagePpde = () => import("@/views/financement/EbeDeclarationMensuelleManagePpde.vue");
const CleRepriseVolontaires = () => import("@/views/volunteersManagement/CleRepriseVolontaires.vue");

const PilotagesCle = () => import("@/views/pilotage_cle/PilotageList.vue");
const PilotageCle = () => import("@/views/pilotage_cle/Pilotage.vue");

const gestionCle = [
  {
    path: "/cle/gestion/",
    component: SimpleContainer,
    meta: {
      title: "Gestion de mon CLE",
      icon: "mdi-town-hall",
      displayInMenu: true,
      disabled: true,
      permissions: ["notrexp:ebe_manage"],
    },
    children: [
      {
        path: "/cle/gestion/ebe",
        name: "cle-gestion-ebe",
        component: ManageEbe,
        meta: {
          title: "Gestion des EBE",
          displayInMenu: true,
          permissions: ["notrexp:ebe_manage"],
        },
      },
    ],
  },
];

const suiviVolontaires = [
  {
    path: "/cle/volontaires/",
    component: SimpleContainer,
    meta: {
      title: "Suivi des volontaires",
      icon: "mdi-table-account",
      displayInMenu: true,
      disabled: true,
      groups: ["cle_admin", "cle_contributeur", "cle_partenaire", "cle_lecture_seule"],
    },
    children: [
      {
        path: "/cle/volontaires/suivi",
        name: "cle-suivi-volontaire-list",
        component: ListViewContainerVue,
        meta: {
          title: "Outil de suivi des volontaires",
          displayInMenu: true,
          permissions: ["suivi_ppde:prospectinfo_list"],
          listComponent: VolunteersManagement,
        },
        children: [
          {
            path: ":id",
            name: "cle-suivi-volontaire",
            component: VolunteerManagement,
            meta: {
              title: "Profil d'un·e volontaire",
              permissions: ["suivi_ppde:prospectinfo_read"],
            },
          },
        ],
      },
      {
        path: "/cle/volontaires/validation-ppde",
        component: EbeDeclarationMensuelleManagePpde,
        meta: {
          title: "Validation de la privation durable d'emploi",
          displayInMenu: true,
          permissions: ["financement:dsnimportsalarie_validatestatus"],
          notificationTriggers: ["financement.salarie_validation_en_attente_cle_in_app"],
        },
      },
      {
        path: "/cle/volontaires/reprise",
        name: "cle-reprise_volontaires",
        component: CleRepriseVolontaires,
        meta: {
          title: "Reprise des volontaires",
          displayInMenu: true,
          permissions: ["suivi_ppde:clereprisevolontaires_read"],
        },
      },
    ],
  },
];

const saisiesPilotage = [
  {
    path: "/cle/pilotage",
    component: PilotagesCle,
    name: "pilotage-cle-list",
    meta: {
      title: "Saisies de pilotage",
      icon: "mdi-bullseye-arrow",
      displayInMenu: true,
      permissions: ["pilotage_cle:pilotagecle_read"],
      groups: ["cle_admin", "cle_contributeur"],
    },
  },
  {
    path: "/cle/pilotage/:id",
    component: PilotageCle,
    name: "pilotage-cle",
    meta: {
      title: "Saisie de pilotage",
      permissions: ["pilotage_cle:pilotagecle_read"],
    },
  },
];

export default [...gestionCle, ...suiviVolontaires, ...saisiesPilotage];
