import { computed } from "vue";
import { useStore } from "@/stores";

const store = useStore();

export default function useAuthentication() {
  const hasPermission = (permission) => store.getters["authentication/hasPermission"](permission);
  const isInGroup = (group) => store.getters["authentication/isInGroup"](group);
  const isInAtLeastOneGroup = (groups) => store.getters["authentication/isInAtLeastOneGroup"](groups);

  const authenticatedUser = computed(() => store.state?.authentication?.user || {});
  const isAuthenticated = computed(() => store.state?.authentication?.authenticated || false);
  const isETCLDUser = computed(() => isInGroup("etcld_admin"));
  const isCLEUser = computed(() => isInAtLeastOneGroup(["cle_admin", "cle_contributeur", "cle_partenaire", "cle_lecture_seule", "cle_ressources"]));
  const isEBEUser = computed(() => isInAtLeastOneGroup(["ebe_admin", "ebe_contributeur", "ebe_lecture_seule", "ebe_ressources"]));

  return {
    hasPermission,
    isInGroup,
    authenticatedUser,
    isAuthenticated,
    isETCLDUser,
    isCLEUser,
    isEBEUser,
  };
}
