import BaseModel from "@/models/BaseModel.js";

export default class Group extends BaseModel {
  static entity = "group";
  static resourceUrl = "/notrexp/group/";

  static ETCLD_GROUPS = ["etcld_admin", "etcld_ressources"];
  static ETCLD_DEFAULT_GROUP = "etcld_admin";

  static CLE_GROUPS = ["cle_lecture_seule", "cle_partenaire", "cle_contributeur", "cle_admin", "cle_ressources"];
  static CLE_DEFAULT_GROUP = "cle_lecture_seule";

  static EBE_GROUPS = ["ebe_admin", "ebe_contributeur", "ebe_lecture_seule", "ebe_ressources"];
  static EBE_DEFAULT_GROUP = "ebe_lecture_seule";

  static primaryKey = "identifier";

  static fields() {
    return {
      identifier: this.attr(""),
      name: this.attr(""),
      description: this.attr(""),
    };
  }

  static getETCLDGroups() {
    return Group.query()
      .where((group) => Group.ETCLD_GROUPS.includes(group.identifier))
      .get();
  }

  static getCLEGroups() {
    return Group.query()
      .where((group) => Group.CLE_GROUPS.includes(group.identifier))
      .get();
  }

  static getEBEGroups() {
    return Group.query()
      .where((group) => Group.EBE_GROUPS.includes(group.identifier))
      .get();
  }
}
