import BaseModel from "@/models/BaseModel";

export default class StatutJuridique extends BaseModel {
  static entity = "statut_juridique";
  static resourceUrl = "/statut_juridique";

  static fields() {
    return {
      id: this.uid(),
      nom: this.attr(),
    };
  }
}
