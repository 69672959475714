import BaseModel from "@/models/BaseModel";

export default class DSNImportSalarieRemunerationCorrection extends BaseModel {
  static entity = "dsn_import_salarie_remuneration_correction";
  static resourceUrl = "/financement/ebe_salarie_dsn_remuneration_correction/";

  static STATUS_CONFIRME = "CONFIRME";
  static STATUS_NON_CORRIGE = "NON_CORRIGE";

  static STATUSES = [
    {
      text: "Validé",
      value: DSNImportSalarieRemunerationCorrection.STATUS_CONFIRME,
      color: "success-bg",
      textColor: "success",
      icon: "mdi-check",
    },
    {
      text: "À valider",
      value: DSNImportSalarieRemunerationCorrection.STATUS_NON_CORRIGE,
      color: "grey lighten-3",
      icon: "mdi-pencil",
    },
  ];

  static fields() {
    return {
      id: this.uid(),
      unite_mesure: this.attr(),
      unite_mesure_original: this.attr(),
      quotite: this.attr(),
      quotite_original: this.attr(),
      quotite_reference: this.attr(),
      quotite_reference_original: this.attr(),
      duree_travail_remuneree: this.attr(),
      duree_travail_remuneree_original: this.attr(),
      duree_absence_non_remuneree: this.attr(),
      duree_absence_non_remuneree_original: this.attr(),
      status_log: this.attr(),
      remuneration: this.attr(),
      optional: this.attr(),
    };
  }

  async validate(isValidated) {
    return await DSNImportSalarieRemunerationCorrection.api().validate(this.id, this, isValidated);
  }

  static apiConfig = {
    actions: {
      validate(id, correction, isValidated) {
        return this.post(`${this.model.resourceUrl}${id}/validate/`, {
          ...correction,
          validate: isValidated,
        });
      },
      ...BaseModel.apiConfig.actions,
    },
  };
}
