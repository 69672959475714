const SimpleContainer = () => import("@/views/SimpleContainer.vue");
const ListViewContainer = () => import("@/views/ListViewContainer.vue");

const EbeView = () => import("@/views/ebe/EbeView.vue");

const ManageEbeRealiseEconomique = () => import("@/views/fec/ManageEbeRealiseEconomique.vue");
const EbeRealiseEconomique = () => import("@/views/fec/EbeRealiseEconomique.vue");

const EtpYears = () => import("@/views/financement/previsionEtp/list/EbeEtpYears.vue");
const EtpMonths = () => import("@/views/financement/previsionEtp/view/EtpMonths.vue");

const EbeDeclarationMensuelle = () => import("@/views/financement/EbeDeclarationMensuelle.vue");
const ManageEbeDeclarationMensuelle = () => import("@/views/financement/ManageEbeDeclarationMensuelle.vue");

const PilotagesEbe = () => import("@/views/pilotage/PilotageList.vue");
const PilotageEbe = () => import("@/views/pilotage/Pilotage.vue");

const AgefiphEbeForm = () => import("@/views/agefiph/AgefiphEbeForm.vue");
const AgefiphSalarieForm = () => import("@/views/agefiph/AgefiphSalarieForm.vue");

const ebeGestion = [
  {
    path: "/ebe/gestion/ebe",
    name: "ebe-gestion-ebe",
    component: EbeView,
    meta: {
      title: "Profil EBE",
      icon: "mdi-office-building-cog-outline",
      displayInMenu: true,
      permissions: ["notrexp:ebe_update_extra_info"],
    },
  },
];

const ebeFinancement = [
  {
    path: "/ebe/financement/declaration-dsn-mensuelle",
    name: "ebe-declaration-dsn-mensuelle",
    component: ManageEbeDeclarationMensuelle,
    meta: {
      title: "Données mensuelles de paie",
      icon: "mdi-file-upload-outline",
      displayInMenu: true,
      permissions: ["financement:dsnimport_read"],
      groups: ["ebe_admin", "ebe_contributeur", "ebe_lecture_seule"],
    },
  },
  {
    path: "/ebe/financement/declaration-dsn-mensuelle/current",
    name: "ebe-declaration-dsn-mensuelle-current",
    component: EbeDeclarationMensuelle,
    meta: {
      title: "Déclaration mensuelle courante",
      permissions: ["financement:dsnimport_update"],
    },
  },
  {
    path: "/ebe/financement/declaration-dsn-mensuelle/:dsnId",
    name: "ebe-declaration-dsn-mensuelle-id",
    component: ManageEbeDeclarationMensuelle,
    meta: {
      title: "Suivi des téléversements des données issues de la paie",
      permissions: ["financement:dsnimport_read"],
    },
  },
  {
    path: "/ebe/financement/prevision-etp",
    name: "ebe-financement-prevision-etp-list",
    component: ListViewContainer,
    meta: {
      title: "Prévisions ETP mensuels",
      icon: "mdi-calendar-multiple-check",
      displayInMenu: true,
      permissions: ["financement:ebeprevisionetp_read"],
      groups: ["ebe_admin", "ebe_contributeur", "ebe_lecture_seule"],
      listComponent: EtpYears,
    },
    children: [
      {
        path: ":id",
        name: "ebe-financement-prevision-etp",
        component: EtpMonths,
        meta: {
          title: "Prévisions ETP par année",
          permissions: ["financement:ebeprevisionetp_read", "financement:ebeprevisionetp_update"],
          groups: ["ebe_admin", "ebe_contributeur", "ebe_lecture_seule"],
        },
      },
    ],
  },
];

const ebeSaisiePilotage = [
  {
    path: "/ebe/pilotage/",
    component: SimpleContainer,
    meta: {
      title: "Saisies de pilotage",
      icon: "mdi-bullseye-arrow",
      displayInMenu: true,
      disabled: true,
      groups: ["ebe_admin", "ebe_contributeur", "ebe_lecture_seule"],
    },
    children: [
      {
        path: "/ebe/pilotage/previsions",
        name: "pilotage-ebe-list",
        component: PilotagesEbe,
        meta: {
          title: "Prévisions",
          displayInMenu: true,
          permissions: ["pilotage:pilotage_read"],
          notificationTriggers: ["pilotage.pilotage_opened_in_app"],
        },
      },
      {
        path: "/ebe/pilotage/previsions/:id",
        name: "pilotage-ebe",
        component: PilotageEbe,
        meta: {
          title: "Prévisionnel économique",
          permissions: ["pilotage:pilotage_read"],
        },
      },
      {
        path: "/ebe/pilotage/realises",
        name: "ebe-realise-economique-list",
        component: ListViewContainer,
        meta: {
          title: "Réalisés (FEC)",
          displayInMenu: true,
          permissions: ["fec:fecimport_read"],
          listComponent: ManageEbeRealiseEconomique,
          notificationTriggers: ["fec.test_fec_temporaire_in_app"],
        },
        children: [
          {
            path: "new",
            name: "ebe-realise-economique-new",
            component: EbeRealiseEconomique,
            meta: {
              title: "Réalisé économique",
              permissions: ["fec:fecimport_read"],
            },
          },
          {
            path: ":id",
            name: "ebe-realise-economique",
            component: EbeRealiseEconomique,
            meta: {
              title: "Réalisé économique",
              permissions: ["fec:fecimport_read"],
            },
          },
        ],
      },
    ],
  },
];

const ebeAgefiphRoutes = [
  {
    path: "/ebe/agefiph",
    component: SimpleContainer,
    meta: {
      title: "Saisies Agefiph",
      icon: "mdi-pen",
      displayInMenu: false,
      disabled: true,
      groups: ["ebe_admin", "ebe_contributeur"],
    },
    children: [
      {
        path: "/ebe/agefiph/ebe",
        name: "ebe-agefiph-formulaire-ebe",
        component: AgefiphEbeForm,
        meta: {
          title: "Formulaire EBE",
          displayInMenu: true,
          groups: ["ebe_admin", "ebe_contributeur"],
        },
      },
      {
        path: "/ebe/agefiph/salarie",
        name: "ebe-agefiph-formulaire-salarie",
        component: AgefiphSalarieForm,
        meta: {
          title: "Formulaire Salarié",
          displayInMenu: true,
          groups: ["ebe_admin", "ebe_contributeur"],
        },
      },
    ],
  },
];

export default [...ebeGestion, ...ebeFinancement, ...ebeSaisiePilotage, ...ebeAgefiphRoutes];
