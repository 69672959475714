import { downloadFile } from "@/helpers/ApiRequest";
import BaseModel from "@/models/BaseModel.js";

export default class DSNImport extends BaseModel {
  static entity = "dsn_import";
  static resourceUrl = "/financement/dsn_import/";

  static STATUS_ACTIVE = "ACTIVE";
  static STATUS_INACTIVE = "INACTIVE";
  static STATUS_VALIDE = "VALIDE";
  static STATUS_ERROR = "ERROR";
  static STATUS_PENDING = "PENDING";

  static STATUSES = [
    { text: "Validé", value: DSNImport.STATUS_VALIDE, color: "green", icon: "mdi-check", canFilter: true },
    { text: "En cours", value: DSNImport.STATUS_ACTIVE, color: "orange", icon: "mdi-pen", canFilter: true },
    { text: "Supprimé", value: DSNImport.STATUS_INACTIVE, color: "red", icon: "mdi-delete", canFilter: false },
  ];

  static IN_PROGRESS_STATUSES = [DSNImport.STATUS_ACTIVE, DSNImport.STATUS_PENDING, DSNImport.STATUS_ERROR];

  static HEURES_ANNUALISEES = "HEURES_ANNUALISEES";
  static HEURES_REELLES = "HEURES_REELLES";
  static HEURES_REELLES_CONVERTIES = "HEURES_REELLES_CONVERTIES";

  static fields() {
    return {
      id: this.uid(),
      mois: this.attr(),
      ebe: this.attr(),
      status: this.attr(),
      hour_type: this.attr(),
      etablissement_sirets: this.attr(),
      import_date: this.attr(),
      errors: this.attr([]),
      worked_days: this.attr(),
    };
  }

  getMois() {
    return new Date(new Date(this.mois).toDateString());
  }

  getImportDate() {
    return new Date(this.import_date);
  }

  getStatus() {
    const status = DSNImport.STATUSES.find((s) => s.value === this.status);
    return status || { text: "Statut inconnu", icon: "mdi-alert", color: "red" };
  }

  getSiretColorMap() {
    let lightnessMin = 40;
    let lightnessMax = 90;
    let siretMap = {};
    let siretList = [...this.etablissement_sirets];
    if (siretList.length < 5) {
      lightnessMin = 90 - siretList.length * 10;
    }
    siretList.sort().reverse();

    for (let i = 0; i < siretList.length; i++) {
      let siret = siretList[i];
      let color = (i / (siretList.length - 1)) * (lightnessMax - lightnessMin) + lightnessMin;
      siretMap[siret] = `hsl(0, 0%, ${color}%)`;
    }

    return siretMap;
  }

  exportAlerts() {
    return DSNImport.api().exportAlerts(this.id);
  }

  static apiConfig = {
    actions: {
      upload(ebe, files, config) {
        let request = new FormData();
        request.set("ebe", ebe);
        for (let file of files) {
          request.append("dsn", file);
        }
        request.set("encoding", "utf-8");
        return this.post(`${this.model.resourceUrl}upload/`, request, config);
      },
      validateDSNImport(id) {
        return this.post(`${this.model.resourceUrl}${id}/validate_dsn_import/`, {});
      },
      checkTask(id) {
        return this.post(`${this.model.resourceUrl}${id}/check_task/`, { save: true });
      },
      computeMaxEtpContractuel(ebe, maxDate) {
        return this.get(`/financement/ebe_salarie_dsn/compute_max_etp_contractuel/`, {
          params: {
            ebe: ebe,
            max_date: maxDate,
          },
          save: false,
        });
      },
      getDefaultWorkedDays(id) {
        return this.get(`${this.model.resourceUrl}${id}/default_worked_days/`, {});
      },
      convertHeuresAnnualisees(id, workedDays) {
        return this.post(`${this.model.resourceUrl}${id}/convert_heures_annualisees/`, {
          worked_days: workedDays,
        });
      },
      async exportAlerts(id) {
        const url = `${process.env.VUE_APP_API_URL}${this.model.resourceUrl}${id}/export_alerts/`;
        await downloadFile(url);
      },
      ...BaseModel.apiConfig.actions,
    },
  };
}
