import Vue from "vue";

import { formatDatetime, formatToDisplay } from "@/helpers/dates.js";

Vue.filter("formatYear", function (value) {
  if (typeof value == "string") {
    return value.split("-")[0];
  }

  if (value instanceof Date) {
    return value.getFullYear();
  }

  return value;
});

Vue.filter("formatDate", function (value, dateType) {
  if (typeof value == "string") {
    return formatToDisplay(value, dateType);
  }

  if (value instanceof Date) {
    let strValue = value.toISOString().split("T")[0];
    return formatToDisplay(strValue, dateType);
  }

  return value;
});

Vue.filter("formatLongDate", function (value) {
  if (typeof value === "string") {
    value = new Date(value);
  }

  if (value instanceof Date) {
    return value.toLocaleDateString("fr", { day: "numeric", month: "long", year: "numeric" });
  }

  return value;
});

Vue.filter("formatDatetime", function (value) {
  return formatDatetime(value);
});

Vue.filter("formatLongMonth", function (value) {
  if (typeof value == "string") {
    value = new Date(value);
  }

  if (value instanceof Date) {
    return value.toLocaleDateString("fr", { month: "long", year: "numeric" });
  }

  return value;
});

Vue.filter("formatMonth", (value) => {
  let result = value;
  if (typeof value == "string") {
    result = new Date(value);
  }

  return result instanceof Date ? result.toLocaleDateString("fr", { month: "long" }) : result;
});

Vue.filter("formatSiret", function (value) {
  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{5})/, "$1 $2 $3 $4");
});

Vue.filter("pluralize", function (amount, plural = "s", singular = "") {
  return amount > 1 ? plural : singular;
});

Vue.filter("truncate", function (text, length) {
  return text.length < length ? text : text.slice(0, length).concat("...");
});
