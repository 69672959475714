import * as Sentry from "@sentry/vue";

import ApiRequest from "@/helpers/ApiRequest.js";
import User from "@/models/User";
import Notification from "@/models/Notification";

export default {
  state: {
    authenticated: false,
    user: null,
  },
  getters: {
    isInGroup: (state) => (groupName) => {
      return !!state.user?.groups.find((group) => group === groupName);
    },
    isInAtLeastOneGroup: (state) => (groupNames) => {
      return !!state.user?.groups.some((group) => groupNames.includes(group));
    },
    hasPermission: (state) => (permissionId) => {
      return !!state.user?.permissions.find((permission) => permission === permissionId);
    },
  },
  actions: {
    async init(context) {
      try {
        const response = await User.api().getCurrent({
          handlingErrors: false,
          handlingAPIUnreachable: false,
        });

        context.commit("LOGIN", {});
        context.commit("AUTHENTICATED", { user: response.entities.user[0] });
        Notification.loadAllNotifications();
      } catch (error) {
        context.commit("LOGOUT");
      }
    },
    async login(context, params) {
      let response = await ApiRequest.post(
        "/users/login/",
        {
          email: params.email,
          password: params.password,
        },
        { handlingErrors: false }
      );

      context.commit("LOGIN", {});
      response = await User.api().getCurrent({ handlingErrors: false });
      context.commit("AUTHENTICATED", { user: response.entities.user[0] });
      Notification.loadAllNotifications();
    },
    async updateAuthenticatedUser(context) {
      const response = await User.api().getCurrent({ handlingErrors: false });
      context.commit("UPDATE_AUTHENTICATED_USER", { user: response.entities.user[0] });
    },
    async logout(context, { postAction } = {}) {
      try {
        await ApiRequest.post("/users/logout/");
      } catch (error) {
        console.error(error);
      } finally {
        context.commit("LOGOUT");
        postAction && (await postAction());
        window.location.reload();
      }
    },
  },
  mutations: {
    AUTHENTICATED(state, params) {
      state.authenticated = true;
      state.user = params.user;
      if (state.user != null) {
        Sentry.setUser({
          id: String(state.user.id),
          email: state.user.email,
        });
      }
    },
    UPDATE_AUTHENTICATED_USER(state, params) {
      state.user = params.user;
    },
    LOGIN() {},
    LOGOUT(state) {
      state.user = null;
      state.authenticated = false;
    },
  },
  namespaced: true,
};
