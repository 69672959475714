import BaseModel from "@/models/BaseModel.js";
import Ebe from "@/models/Ebe.js";

export default class Cle extends BaseModel {
  static entity = "cle";
  static resourceUrl = "/cle/";

  static TITLE_ITEMS = [
    { text: "Madame", value: "MADAME" },
    { text: "Monsieur", value: "MONSIEUR" },
    { text: "Non communiqué", value: "NON_COMMUNIQUE" },
  ];

  static fields() {
    return {
      id: this.uid(),
      nom: this.string(""),
      created_datetime: this.attr(),
      // included in the createCle
      utilisateur: this.attr(),
      // included in the extra_infos
      date_arrete_habilitation: this.attr(),
      epci: this.attr(),
      slug: this.attr(),
      villes: this.attr(),
      adresse: this.attr(),
      presidents: this.attr(),
    };
  }

  getEbes() {
    return Ebe.query()
      .where((ebe) => ebe.cles.includes(this.id))
      .orderBy("nom")
      .get();
  }
}
