import { Model } from "@vuex-orm/core";

export default class Smic extends Model {
  static entity = "smic";

  static fields() {
    return {
      id: this.uid(),
      montant: this.attr(),
      usage_date: this.attr(),
    };
  }

  getUsageDate() {
    return new Date(new Date(this.usage_date).toDateString());
  }

  static state() {
    return {
      allLoaded: false,
    };
  }

  static get FULL_TIME_SMIC_HOURS() {
    return 151.67;
  }

  static computeYear(year, maxDate) {
    let allSmic = Smic.query();

    if (maxDate) {
      allSmic = allSmic.where((smic) => smic.getUsageDate() <= maxDate);
    }

    allSmic = allSmic.get().sort((a, b) => b.getUsageDate() - a.getUsageDate());
    let last_date = new Date(new Date(year).getFullYear(), 11, 31 + 1);
    let values = [];

    for (let smic of allSmic) {
      let usage_date = smic.getUsageDate();
      if (usage_date > last_date) {
        continue;
      }

      let months = last_date.getMonth() - usage_date.getMonth();
      months += 12 * (last_date.getFullYear() - usage_date.getFullYear());
      months = Math.min(months, 12 - values.length);

      last_date = usage_date;
      for (let i = 0; i < months; i++) {
        values.unshift(smic.montant * this.FULL_TIME_SMIC_HOURS);
      }

      if (values.length >= 12) {
        break;
      }
    }

    let unknownValues = 12 - values.length;
    for (let i = 0; i < unknownValues; i++) {
      values.unshift(0.0);
    }

    return {
      months: values,
      total: Math.round(values.reduce((a, b) => a + b, 0) * 100) / 100,
      totalDecemberValue: values[11] * 12,
    };
  }

  static apiConfig = {
    actions: {
      loadAll() {
        let state = this.model.store().state.entities[this.model.entity];
        if (state.allLoaded) {
          return Promise.resolve();
        }
        return this.get("/smic/").then(() => {
          this.model.commit((state) => {
            state.allLoaded = true;
          });
        });
      },
    },
  };
}
