<template>
  <div>
    <v-dialog
      :persistent="modal.persistent"
      content-class="col-11 col-sm-8 col-md-6 col-lg-4 pa-0"
      v-for="modal in modals"
      :key="modal.message"
      v-model="modal.open"
      @click:outside="($event) => emitClose(modal, $event)"
      @keydown="($event) => emitClose(modal, $event)"
    >
      <v-card flat>
        <v-alert border="left" :color="modal.type === 'confirm' ? 'primary' : modal.type" colored-border elevation="0" class="mb-0">
          <v-card-title>
            <v-icon v-if="modal.type === 'error'" color="error" left>mdi-alert-circle-outline</v-icon>
            <v-icon v-if="modal.type === 'warning'" color="warning" left>mdi-alert-outline</v-icon>
            <v-icon v-if="modal.type === 'confirm'" color="primary" left>mdi-help-circle-outline</v-icon>
            <span class="text-h6 font-weight-light">{{ modal.title }}</span>
          </v-card-title>
          <v-card-text v-if="modal.message" style="white-space: pre-wrap">{{ modal.message }}</v-card-text>
          <v-card-text v-else-if="modal.messages">
            <ul>
              <li v-for="(message, i) in modal.messages" :key="i">
                {{ message }}
              </li>
            </ul>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text v-if="modal.type !== 'confirm'" @click.stop="emitOk(modal)">OK</v-btn>
            <v-btn color="primary" text v-if="modal.type === 'confirm' && modal.cancelText !== null" @click.stop="emitCancel(modal)">
              {{ modal.cancelText || "Annuler" }}
            </v-btn>
            <v-btn color="primary" depressed v-if="modal.type === 'confirm'" @click.stop="emitConfirm(modal)" :loading="modal.confirmLoading">
              {{ modal.confirmText || "Confirmer" }}
            </v-btn>
          </v-card-actions>
        </v-alert>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  computed: {
    modals() {
      return this.$store.state.modal.modals;
    },
  },
  methods: {
    emitClose(modal, e) {
      if (modal.persistent) return;
      const shouldClose = e.type === "click" || (e.type === "keydown" && e.key === "Escape");
      if (shouldClose) {
        this.$store.dispatch("modal/closeModal", modal);
        modal.resolve({ close: true });
      }
    },
    async emitConfirm(modal) {
      let response = { confirmBtn: true };

      if (modal.actionPromise) {
        this.$store.dispatch("modal/updateModal", { modal, changes: { confirmLoading: true } });
        response = await modal.actionPromise(response);
        this.$store.dispatch("modal/updateModal", { modal, changes: { confirmLoading: false } });
      }

      modal.resolve(response);
      this.$store.dispatch("modal/closeModal", modal);
    },
    async emitCancel(modal) {
      let response = { confirmBtn: false };

      this.$store.dispatch("modal/closeModal", modal);

      if (modal.actionPromise) {
        response = await modal.actionPromise(response);
      }

      modal.resolve(response);
      this.$store.dispatch("modal/closeModal", modal);
    },
    emitOk(modal) {
      this.$store.dispatch("modal/closeModal", modal);
      modal.resolve({ okBtn: true });
    },
  },
};
</script>
<style lang="scss" scoped>
div::v-deep .v-card__title {
  word-break: initial;
}
</style>
