import BaseModel from "@/models/BaseModel.js";

import Cle from "@/models/Cle.js";

export default class Ebe extends BaseModel {
  static entity = "ebe";
  static resourceUrl = "/ebe/";

  static fields() {
    return {
      id: this.uid(),
      nom: this.string(""),
      cles: this.attr(),
      embauche_premiers_date: this.attr(),

      // included in the extra_infos
      ca_conventionnement_date: this.attr(),
      code_ape: this.attr(),
      code_rna: this.attr(),
      opco: this.attr(),
      idcc: this.attr(),
      created_datetime: this.attr(),
      creation_personne_morale_date: this.attr(),
      debut_prise_en_charge_mois: this.attr(),
      siren: this.attr(),
      slug: this.attr(),
      statut: this.attr(),

      // included in the address_infos and extra_infos
      adresse: this.attr(),
      ville: this.attr(),
    };
  }

  getCles() {
    return Cle.query().whereIdIn(this.cles).orderBy("nom").get();
  }

  getEmbauchePremiersDate() {
    if (!this.embauche_premiers_date) {
      return;
    }

    return new Date(this.embauche_premiers_date);
  }
}
