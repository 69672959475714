const Annuaire = () => import("@/views/Annuaire.vue");

const routes = [
  {
    path: "/annuaire",
    component: Annuaire,
    name: "annuaire-list",
    meta: {
      title: "Annuaire",
      icon: "mdi-account-box-multiple-outline",
      // icon: "mdi-folder-account-outline",
      displayInMenu: true,
    },
  },
  {
    path: "/annuaire/:id",
    component: Annuaire,
    name: "annuaire-profile",
  },
];

export default routes;
