import AttachedS3File from "@/models/AttachedS3File";
import BaseModel from "@/models/BaseModel";

export default class BilanGens extends BaseModel {
  static entity = "bilan_gens";
  static resourceUrl = "/bilan/bilan_gens/";

  static STATUS = Object.freeze({
    ACTIVE: "ACTIVE",
    VALIDE: "VALIDE",
  });

  static STATUSES = Object.freeze([
    {
      text: "À compléter",
      value: BilanGens.STATUS.ACTIVE,
      color: "grey",
      textColor: "white",
      icon: "mdi-pen",
    },
    {
      text: "Validé",
      value: BilanGens.STATUS.VALIDE,
      color: "green",
      textColor: "white",
      icon: "mdi-check",
    },
  ]);

  static TEMOIN_STATUS = Object.freeze({
    CHEF_PROJET: "CHEF_PROJET",
    ELU: "ELU",
    PRESIDENT_CLE: "PRESIDENT_CLE",
    TECHNICIEN_CLE: "TECHNICIEN_CLE",
    PRESIDENT_EBE: "PRESIDENT_EBE",
    DIRECTEUR_EBE: "DIRECTEUR_EBE",
    GOUVERNANCE_EBE: "GOUVERNANCE_EBE",
    ENCADRANT_EBE: "ENCADRANT_EBE",
    SALARIE_EBE: "SALARIE_EBE",
    PERSONNE_SORTIE_HORS_EBE: "PERSONNE_SORTIE_HORS_EBE",
    CLIENT_EBE: "CLIENT_EBE",
    PPDE: "PPDE",
    CITOYEN: "CITOYEN",
    AUTRE: "AUTRE",
  });

  static TEMOIN_STATUSES = Object.freeze([
    {
      text: "Chef·fe de projet",
      value: BilanGens.TEMOIN_STATUS.CHEF_PROJET,
      options: {
        cleSelectable: true,
        cleRequired: true,
      },
    },
    {
      text: "Citoyen·ne ou bénévole",
      value: BilanGens.TEMOIN_STATUS.CITOYEN,
      options: {
        cleSelectable: true,
        ebeSelectable: true,
      },
    },
    {
      text: "Client·e de l'EBE",
      value: BilanGens.TEMOIN_STATUS.CLIENT_EBE,
      options: {
        ebeSelectable: true,
        ebeRequired: true,
      },
    },
    {
      text: "Directeur·ice d'EBE",
      value: BilanGens.TEMOIN_STATUS.DIRECTEUR_EBE,
      options: {
        ebeSelectable: true,
        ebeRequired: true,
      },
    },
    {
      text: "Élu·e",
      value: BilanGens.TEMOIN_STATUS.ELU,
      options: {
        cleSelectable: true,
      },
    },
    {
      text: "Encadrant·e intermédiaire d'EBE",
      value: BilanGens.TEMOIN_STATUS.ENCADRANT_EBE,
      options: {
        ebeSelectable: true,
        ebeRequired: true,
      },
    },
    {
      text: "Membre de la gouvernance d'EBE",
      value: BilanGens.TEMOIN_STATUS.GOUVERNANCE_EBE,
      options: {
        ebeSelectable: true,
        ebeRequired: true,
      },
    },
    {
      text: "Personne Privée Durablement d'Emploi",
      value: BilanGens.TEMOIN_STATUS.PPDE,
      options: {
        cleSelectable: true,
        cleRequired: true,
      },
    },
    {
      text: "Personne sortie de la privation d'emploi hors EBE",
      value: BilanGens.TEMOIN_STATUS.PERSONNE_SORTIE_HORS_EBE,
      options: {
        cleSelectable: true,
        cleRequired: true,
      },
    },
    {
      text: "Président·e d'EBE",
      value: BilanGens.TEMOIN_STATUS.PRESIDENT_EBE,
      options: {
        ebeSelectable: true,
        ebeRequired: true,
      },
    },
    {
      text: "Président·e de CLE",
      value: BilanGens.TEMOIN_STATUS.PRESIDENT_CLE,
      options: {
        cleSelectable: true,
        cleRequired: true,
      },
    },
    {
      text: "Salarié·e d'EBE",
      value: BilanGens.TEMOIN_STATUS.SALARIE_EBE,
      options: {
        ebeSelectable: true,
        ebeRequired: true,
      },
    },
    {
      text: "Technicien·ne participant·e au CLE",
      value: BilanGens.TEMOIN_STATUS.TECHNICIEN_CLE,
      options: {
        cleSelectable: true,
        cleRequired: true,
      },
    },
    {
      text: "Autre",
      value: BilanGens.TEMOIN_STATUS.AUTRE,
      options: {
        cleSelectable: true,
        ebeSelectable: true,
      },
    },
  ]);

  static CONTENT_FORMAT = Object.freeze({
    TEXTE: "TEXTE",
    AUDIO: "AUDIO",
    VIDEO: "VIDEO",
    AUTRE: "AUTRE",
  });

  static CONTENT_FORMATS = Object.freeze([
    {
      text: "Texte",
      value: BilanGens.CONTENT_FORMAT.TEXTE,
    },
    {
      text: "Audio",
      value: BilanGens.CONTENT_FORMAT.AUDIO,
      icon: "mdi-volume-high",
      authorizedFileTypes: [AttachedS3File.AUDIO],
    },
    {
      text: "Vidéo",
      value: BilanGens.CONTENT_FORMAT.VIDEO,
      icon: "mdi-video",
      authorizedFileTypes: [AttachedS3File.VIDEO],
    },
    {
      text: "Autre",
      value: BilanGens.CONTENT_FORMAT.AUTRE,
      icon: "mdi-file-document",
      authorizedFileTypes: [AttachedS3File.IMAGE, AttachedS3File.PDF],
    },
  ]);

  static getContentFormat = (input) => {
    const contentFormat = BilanGens.CONTENT_FORMATS.find((format) => format.value === input);
    return contentFormat ?? { text: "Format inconnu" };
  };

  static getStatusValuesFromOption = (option) => {
    return BilanGens.TEMOIN_STATUSES.filter(({ options }) => options?.[option]).map(({ value }) => value);
  };

  static S3_TARGET_QUESTION_CONTENT_FILE = "bilan.bilan_gens.question_content_file";
  static S3_TARGET_QUESTION_ILLUSTRATION = "bilan.bilan_gens.question_illustration";
  static S3_TARGET_ACCORD_CAPTATION = "bilan.bilan_gens.accord_captation";

  static fields() {
    return {
      id: this.uid(),
      status_log: this.attr(),
      created_etcld: this.attr(),
      created_cle: this.attr(),
      created_ebe: this.attr(),
      question_temoin_nom: this.attr(),
      question_temoin_nom_discretion: this.attr(),
      question_temoin_statut: this.attr(),
      question_temoin_statut_autre: this.attr(),
      question_temoin_statut_discretion: this.attr(),
      question_temoin_ebe: this.attr(),
      question_temoin_cle: this.attr(),
      question_temoin_territoire_discretion: this.attr(),
      question_titre: this.attr(),
      question_date: this.attr(),
      question_format: this.attr(),
      question_content_texte: this.attr(),
      question_content_file: this.attr(),
      question_illustration: this.attr(),
      accord_captation: this.attr(),
      accord_charte: this.attr(),
      accord_rgpd: this.attr(),
      question_info_supplementaire: this.attr(),
    };
  }

  get currentStatus() {
    const status = BilanGens.STATUSES.find((status) => status.value === this.status_log[0].value);
    return status ?? { text: "Statut inconnu", color: "red", icon: "mdi-close" };
  }

  get addedDate() {
    return this.status_log.at(-1).datetime.split("T")[0];
  }

  get modifiedDate() {
    return this.status_log[0].datetime.split("T")[0];
  }

  get fullTitle() {
    return `${this.question_temoin_nom ?? "Nom non renseigné"} – ${this.question_titre ?? "Titre non renseigné"}`;
  }

  static apiConfig = {
    actions: {
      ...BaseModel.apiConfig.actions,
      validate(id) {
        return this.post(`${this.model.resourceUrl}${id}/validate/`, {});
      },
      bulkAddStatus(bilans, status) {
        const data = {
          status,
          bilan_ids: bilans.map(({ id }) => id),
        };
        return this.post(`${this.model.resourceUrl}update_status/`, data);
      },
    },
  };
}
